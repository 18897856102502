export const REQUEST_UPDATE_DATA = 1;
export const REQUEST_CONNEXION = 2;
export const REQUEST_CONNEXION_GOOGLE = 4;
export const REQUEST_GALLERIE_GET_ALL = 5;
export const REQUEST_GALLERIE_GET_PICS = 6;
export const REQUEST_GALLERIE_GET_PICS_ALL = 7;
export const REQUEST_GALERIE_CREATE = 8;
export const REQUEST_GALERIE_DELETE = 9;
export const REQUEST_GALERIE_AJOUT_ACCES = 10;
export const REQUEST_SEND_TOKEN_FIR = 11;
export const REQUEST_STOP_VIDEO_MANAGER = 12;
export const REQUEST_GALERIE_MOD = 13;
export const REQUEST_GALERIE_GET_USER = 14;
export const REQUEST_GALERIE_GET_NOTIFICATION = 15;
export const REQUEST_GALERIE_DEL_PICTURE = 16;
export const REQUEST_GALERIE_GET_CONTACT = 17;
export const REQUEST_GALERIE_GET_CONTACT_DMDE = 18;
export const REQUEST_GALERIE_CREATE_CONTACT_DMDE = 19;
export const REQUEST_GALERIE_SET_CONTACT_ANS = 20;
export const REQUEST_GALERIE_DEL_CONTACT = 21;
export const REQUEST_GET_MESSAGE = 22;
export const REQUEST_ADD_MESSAGE = 23;
export const REQUEST_GET_COMMENTAIRE = 24;
export const REQUEST_ADD_COMMENTAIRE = 25;
export const REQUEST_RENAME_PIC = 26;
export const REQUEST_DELETE_COMM = 27;
export const REQUEST_NOTIF_NEW_PIC = 28;
export const REQUEST_LEAVE_GALERIE = 29;
export const REQUEST_USER_SEARCH = 30;
export const REQUEST_GET_CONVERSATION = 31;
export const REQUEST_SET_NOTIF_SEEN = 32;
export const REQUEST_SET_CONSENTEMENT = 33;
export const REQUEST_SET_LIKE = 34;
export const REQUEST_DMDE_COLOR = 36;
export const CST_ALBUM = 'imp_album';
export const CST_PICTURE = 'imp_picture';
export const CST_ACTION = 'imp_action';
export const CST_NOTIFICATION = 'imp_notification';
export const CST_CONTACT = 'imp_contact';
export const CST_CONTACT_DMDE = 'imp_contact_dmde';
export const CST_MESSAGE = 'imp_message';
export const CST_USER = 'imp_user';
export const CST_COLOR = 'imp_color';