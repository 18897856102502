import { Component, Fragment } from 'react';
import '../Impory.css';
import {Circles} from "react-loader-spinner";
import * as CstRequest from '../constant/constantRequest';
import * as apiStandard from '../api/apiStandard';
import AlbumImpItem from './AlbumImpItem';
import { Card, Form } from 'react-bootstrap';
//import * as Constants from '../constant/constant';
//import { Nav, Badge } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome, faEnvelope, faFilm, faUserCircle, faBell, faShoppingCart, faCopyright} from '@fortawesome/free-solid-svg-icons'
class AlbumImp extends Component {
	
	static obj;
	
	state = {
		isLoading: false,
		//error:'',
		albums: [],
		galerieName:'',
		searchValue:'',
	}
	
	constructor(props){
		super(props);
		AlbumImp.obj = this;
		this.refresh();
	}

	refresh = () => {
		apiStandard.sendRequest(CstRequest.CST_ALBUM, CstRequest.REQUEST_GALLERIE_GET_ALL, [], this.onAlbumLoad, this.onAlbumLoadError);
	}

	onAlbumLoadError = (strucName, idRequest, data, reponse) => {
		this.setState({isLoading: false})
	}
	onAlbumLoad = (strucName, idRequest, data, reponse) => {
		this.setState({albums:reponse,isLoading: true});
	}
	hdlCreateGalerie = () => {
		apiStandard.sendRequest(CstRequest.CST_ACTION, CstRequest.REQUEST_GALERIE_CREATE, 
									[this.state.galerieName, this.state.galerieName], this.onGalerieCreateDone, this.onGalerieCreateError);
	}
	onGalerieCreateError = (strucName, idRequest, data, reponse) => {
		this.setState({galerieName: ''});
	}
	onGalerieCreateDone = (strucName, idRequest, data, reponse) => {
		this.setState({galerieName: ''});
		this.refresh();
	}
	handleGalerieName = (e) => {
		this.setState({galerieName: e.target.value});
	}
	handleInSearch = (e) => {
		this.setState({searchValue : e.target.value});
	}

	render(){
		var content = '';
		var centerContent = '';
		if(this.state.isLoading){
			centerContent = (
				<div className="col-12 rowImp contentMarger">
					<div className="col-xl-2 col-lg-2 col-md-3 col-12">
						<Card className="basicContainer">
							<Card.Body>
								<Card.Title>Rechercher</Card.Title>
								<Form>
									<Form.Group>
										<Form.Label>Nom de la galerie à chercher</Form.Label>
										<Form.Control type="text" placeholder="Rechercher" value={this.state.searchValue} onChange={this.handleInSearch}/>
									</Form.Group>
								</Form>
							</Card.Body>
						</Card>
						<Card className="createGalerieContainer">
							<Card.Body>
								<Card.Title>Créer une galerie</Card.Title>
								<Form>
									<Form.Group controlId="galerieCreateName">
										<Form.Label>Nom de la galerie</Form.Label>
										<Form.Control type="text" placeholder="Nom" value={this.state.galerieName} onChange={this.handleGalerieName}/>
									</Form.Group>
								</Form>
								<button onClick={() => {this.hdlCreateGalerie()}} type="submit" class="btn btn-info">Créer la galerie</button>
							</Card.Body>
						</Card>
					</div>
					<div className="col-xl-10 col-lg-10 col-md-9 col-12 contentMarger">
						<div className="text-center text-lg-left rowImp">
						{
							this.state.albums.imp_album.map((album, index) =>{
								var ok = false;
								if(this.state.searchValue!==""){
									if(album.name === undefined){
										ok = false;
									}else{
										if(album.name.toUpperCase().includes(this.state.searchValue.toUpperCase(), 0)){
											ok = true;
										}
									}
								}else{
									ok = true;
								}
								
								if(ok){
									return(
										<div key={index} className="col-xl-3 col-lg-4 col-md-6 col-12 albumContainer">
											<AlbumImpItem album={album} hdlCP={this.props.hdlCP}/>
										</div>
									)
								}
							})
						}
						</div>
					</div>
				</div>
			);
		}else{
			centerContent = (
				<div className="col-8" align="center">
					<Circles
						color="#FF6D14"
						height={100}
						width={100}
						wrapperClass="loading"
					/>
				</div>
			);
		}

		content = (
			<div className="rowImp">
				{centerContent}
			</div>
		);
    	return content;
  	};
	componentWillUnmount(){
		AlbumImp.obj = undefined;
	}
}
export default AlbumImp;