import { Component, Fragment } from 'react';
import '../Impory.css';
import * as Constants from '../constant/constant';
import Impory from '../Impory';
import { Button } from 'react-bootstrap';
import * as apiStandard from '../api/apiStandard';
import * as CstRequest from '../constant/constantRequest';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome, faEnvelope, faFilm, faUserCircle, faBell, faShoppingCart, faCopyright} from '@fortawesome/free-solid-svg-icons'
class PictureImpFull extends Component {
	
	static obj;
	
	state = {
		//isLoading: true,
		//error:'',
	}
	
	constructor(props){
		super(props);
		PictureImpFull.obj = this;
	}

	hdlRetourGalerie = () => {
		this.props.parent.setState({inFullScreen : false, inFullScreenImage : undefined, inFullScreenIndex : undefined});
	}
	hdlDemandeColorisation = () => {
		console.log("Demande colorisation");
		var dataColorPics = [this.props.pic.id];
		console.log("Send request : " + CstRequest.REQUEST_DMDE_COLOR);
		this.setState({isLoading: false});
		apiStandard.sendRequest(CstRequest.CST_COLOR, CstRequest.REQUEST_DMDE_COLOR, 
									dataColorPics, this.onPictureColor, this.onPictureColorError);
	}
	onPictureColorError = (strucName, idRequest, data, reponse) => {
		console.log("Request " + idRequest + " terminated: ko " + reponse);
	}
	onPictureColor = (strucName, idRequest, data, reponse) => {
		console.log("Request " + idRequest + " terminated: ok " + reponse);
	}
	hdlPrecedentGalerie = (picture, index) => {
		this.props.parent.setState({inFullScreen : true, inFullScreenImage : picture, inFullScreenIndex : index});
	}
	hdlSuivantGalerie = (picture, index) => {
		this.props.parent.setState({inFullScreen : true, inFullScreenImage : picture, inFullScreenIndex : index});
	}

	render(){
		const pic = this.props.pic;
		const index = this.props.index;

		var indexPrec = index - 1;
		if(indexPrec < 0){
			indexPrec = this.props.parent.state.picturesDisplay.length - 1;
		}
		console.log("Prec index : " + indexPrec);
		var precImage = this.props.parent.state.picturesDisplay[indexPrec];
		
		var indexNext = index + 1;
		if(this.props.parent.state.picturesDisplay.length <= indexNext){
			indexNext = 0;
		}
		console.log("Prec Suivant : " + indexNext);
		var nextImage = this.props.parent.state.picturesDisplay[indexNext];

		var src = Constants.WS_PIC_MANAGER + "?tkn=" + Impory.obj.state.impToken + "&id1=" + pic.id + '&std&id2=' + Impory.obj.state.userConnected.id;
		var content = '';
		content = (
			<div className="col-12 rowImp contentMarger fullscreen">
        		<div className="col-xl-2 col-lg-2 col-md-3 col-12 fullScreenMenuContainer">
					<br/>
					<Button variant="secondary" onClick={() => {this.hdlPrecedentGalerie(precImage, indexPrec)}}>Precedent</Button>
					<Button style={{marginLeft:10,}} variant="secondary" onClick={() => {this.hdlSuivantGalerie(nextImage, indexNext)}}>Suivant</Button>
					<br/>
					<br/>
					<Button variant="danger" onClick={() => {this.hdlRetourGalerie()}}>Retour</Button>
				</div>
				<div className="col-xl-10 col-lg-10 col-md-9 col-12 contentMarger fullScreenContainer">
					<img className="imgImporyFull rowVCenter" src={src}/>
				</div>
      		</div>
		);
    	return content;
  	};
	componentWillUnmount(){
		PictureImpFull.obj = undefined;
	}
}
export default PictureImpFull;