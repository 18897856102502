import Impory from '../Impory';
import * as Constants from '../constant/constant';

export async function sendRequest(strucName, idRequest, data, functionBack, functionBackError){
	// Définition des headers de la requête
	const headers = new Headers().append('Content-type', 'application/json');
	var request = {};

	// Boucle sur les data pour ajout dans la requête
	request.idUser = Impory.obj.state.userConnected.id;
	request.token = Impory.obj.state.impToken;
	request.idVersion = Constants.numeroVersion;
	request.STRUC = strucName;
	request.type_request = idRequest;

	// Ecriture générique
	let value = "";
	let varName = "";
	for (var i = 0; i < data.length; i++) {
		value=data[i];
		varName = "param" + (i + 1);
		request[varName] = value;
	}

	// Génération de la requête finale
	var requestOut = {};
	requestOut={request};

	// Génération des options & de l'objet de la requete
	const options = {method : 'POST',headers,body: JSON.stringify(requestOut)};
	const req = new Request(Constants.WS_REQ_MANAGER, options);

	// Log
	console.log("Requête STD out : ", JSON.stringify(requestOut));

	// Exécution
	await fetch(req)
	.then((response) => response.json())
		.then((responseJson) => {
			functionBack(strucName, idRequest, data, responseJson);
		})
		.catch((error) => {
			functionBackError(strucName, idRequest, data, error);
		});
}