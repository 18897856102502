import { Component } from 'react';
import '../Impory.css';
import * as Constants from '../constant/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faCopyright} from '@fortawesome/free-solid-svg-icons'
class FooterImp extends Component {
	
	static obj;
	
	state = {
		//isLoading: true,
		//error:'',
	}
	
	constructor(props){
		super(props);
		const spr = this.props.spr;
		FooterImp.obj = this;
	}
	render(){
		var content = '';
		content = (
			<div className="contentFooter rowImp">
				<div className="col-md-4 col-12">
					Impory - {Constants.T_F_PROPOS}
				</div>
				<div className="col-md-4 col-12">
					<FontAwesomeIcon icon={faCopyright}/> {Constants.T_F_DROIT_RESERVE}	
				</div>
				<div className="col-md-4 col-12">
					<a href="https://impory.com/CGU/regles_confidentialite.html" target="_blank" class="aDoc"><FontAwesomeIcon icon={faFile}/> {Constants.T_F_NORME_CONFIDENTIALITE}</a>
					<br/><a href="https://impory.com/CGU/CGU.html" target="_blank" class="aDoc"><FontAwesomeIcon icon={faFile}/> {Constants.T_F_COND_UTILISATION}</a>
				</div>
			</div>
		);
    	return content;
  	};
	componentWillUnmount(){
		FooterImp.obj = undefined;
	}
}
export default FooterImp;