import * as Constants from '../constant/constant';
import PictureImp from '../compmetier/PictureImp';
import Impory from '../Impory';

export async function sendPicture(obj, file, functionBack){
	const headers = new Headers().append('Content-type', 'application/json');
	var requestOut = {
		request:{
			idUser:Impory.obj.state.userConnected.id,
			token: Impory.obj.state.impToken,
			idAlbum: Impory.obj.state.albumUpload.id,
			idVersion: Constants.numeroVersion,
			orientation: 0,
			f:file,
		}
	}
	console.log(JSON.stringify(requestOut).slice(0, 10000));
	const options = {method : 'POST',headers,body: JSON.stringify(requestOut)};
	const request = new Request(Constants.WS_PIC_UPLOAD, options);
	await fetch(request)
	.then((response) => response.json())
		.then((responseJson) => {
			console.log(responseJson);
			functionBack();
		})
		.catch((error) => {
			console.log(error);
		});
	
	// Debug upload
	/*await fetch(request)
		.then((response) => {console.log(response.text())});*/
}