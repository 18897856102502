import { Component } from 'react';
import NavbarImp from './comstruc/NavbarImp';
import FooterImp from './comstruc/FooterImp';
import AcceuilImp from './compmetier/AcceuilImp';
import AlbumImp from './compmetier/AlbumImp';
import CommandeImp from './compmetier/CommandeImp';
import SocialImp from './compmetier/SocialImp';
import MessageImp from './compmetier/MessageImp';
import NotificationImp from './compmetier/NotificationImp';
import PictureImp from './compmetier/PictureImp';
import * as Constant from './constant/constant';
import * as apiUpload from './api/apiUpload'
import Resizer from 'react-image-file-resizer';
import * as apiStandard from './api/apiStandard';
import * as CstRequest from './constant/constantRequest';
import EXIF from 'exif-js';
import './Impory.css';

class Impory extends Component {

	static obj;

	state = {
		// Connexion
		isConnected:false,
		userConnected: null, 
		impToken : '',
		// Page
		currentPage : Constant.PAGE_ACCEUIL,
		currentPageData : [],
		// Upload area
		picturesUploads:[],
		picturesUploadsUrls:[],
		albumUpload:{},
		pictureUploadCurrent:0,
		pictureUploadMax:0,
		inUpload:false,
		uploadProgression:0,
	}

	constructor(props){
		super(props);
		Impory.obj=this;
	}

	hdlChangePage = (idPage, dataPage) =>{
		this.setState({currentPage: idPage, currentPageData : dataPage});
	}

	hdlConnexionSuccess = (user) => {
		this.setState({userConnected: user, isConnected: true, impToken: user.token});
		console.log("hdlConnexionSuccess OK");
	}

	handleDropPicture = (pictureFiles, pictureDataURLs) =>{
		this.setState({pictureUploadMax:pictureFiles.length});
		this.setState({
			picturesUploads: pictureFiles,
			picturesUploadsUrls: pictureDataURLs,
			albumUpload : PictureImp.obj.state.album,
        });
	}

	hdlOnRemovePicture = (index) => {
		this.state.picturesUploads.splice(index, 1);
		this.state.picturesUploadsUrls.splice(index, 1);
		var arrayFile = this.state.picturesUploads;
		var arrayFileUrl = this.state.picturesUploadsUrls;
		// On force la MAJ
		this.setState({picturesUploadsUrls:arrayFileUrl, picturesUploads:arrayFile});
	}

	resizeFile = (file) => new Promise(resolve => {
		Resizer.imageFileResizer(file, 2048, 2048, 'PNG', 100, 0,
		uri => {
		  resolve(uri);
		},
		'base64'
		);
	});

	handleLogoutConnexionGoogle = () =>{
		this.setState({isConnected:false,userConnected: null});
	}

	handleUploadStart = async () =>{
		let percentageDone = (100 * (this.state.pictureUploadMax - this.state.picturesUploadsUrls.length)) / this.state.pictureUploadMax;
		this.setState({inUpload:true, uploadProgression:percentageDone});
		let file = this.state.picturesUploadsUrls.shift(); 
		let fileB = this.state.picturesUploads.shift(); 
		
		if(file!==undefined){
			console.log('EXIF PROCESS');
			await EXIF.getData(fileB, () => {
				console.log('PROCESS2');
				var exifData = EXIF.pretty(this);
				console.log(exifData);
				if (exifData) {
					var orientation = EXIF.getTag(this, "Orientation");
					console.log('orientation');
					console.log(orientation);
				}else{
					console.log('Pas d exif');
				}
			});
		}

		if(fileB!==undefined){
			apiUpload.sendPicture(Impory.obj, await this.resizeFile(fileB), this.handleUploadStart);	
		}else{
			console.log(PictureImp.obj);
			console.log(PictureImp.obj.state.album.id + " - " + this.state.albumUpload.id);
			if(PictureImp.obj!==undefined){
				if(PictureImp.obj.state.album.id === this.state.albumUpload.id){
					PictureImp.obj.refreshData();
				}
			}
			apiStandard.sendRequest(CstRequest.CST_ACTION, CstRequest.REQUEST_NOTIF_NEW_PIC, [this.state.albumUpload.id, this.state.pictureUploadMax], 
										this.onNotifPicDone, this.onNotifPicError);
			this.setState({inUpload:false});
		}
	}
	onNotifPicError = (strucName, idRequest, data, reponse) => {
		console.log("Notif send error : " + reponse);
		this.setState({albumUpload: {}});
	}
	onNotifPicDone = (strucName, idRequest, data, reponse) => {
		console.log("Notif send OK : " + reponse);
		this.setState({albumUpload: {}});
	}

	render(){
		var content = '';
		if(this.state.currentPage===Constant.PAGE_ACCEUIL){
			content = (<AcceuilImp hdlCP={this.hdlChangePage} hdlConnexionSuccess={this.hdlConnexionSuccess} hdlDeco={this.handleLogoutConnexionGoogle}/>);
		}else if(this.state.currentPage===Constant.PAGE_ALBUM){
			content = (<AlbumImp hdlCP={this.hdlChangePage}/>);
		}else if(this.state.currentPage===Constant.PAGE_COMMANDE){
			content = (<CommandeImp hdlCP={this.hdlChangePage}/>);
		}else if(this.state.currentPage===Constant.PAGE_SOCIAL){
			content = (<SocialImp hdlCP={this.hdlChangePage}/>);
		}else if(this.state.currentPage===Constant.PAGE_MESSAGE){
			content = (<MessageImp hdlCP={this.hdlChangePage}/>);
		}else if(this.state.currentPage===Constant.PAGE_NOTIFICATION){
			content = (<NotificationImp hdlCP={this.hdlChangePage}/>);
		}else if(this.state.currentPage===Constant.PAGE_PICTURES){
			content = (<PictureImp hdlCP={this.hdlChangePage} album={this.state.currentPageData} 
						hdlOnDropPic={this.handleDropPicture} hdlUpload={this.handleUploadStart}
						hdlOnRemovePicture={this.hdlOnRemovePicture}/>);
		}

    	return (
      		<div className="Impory">
				<div className="contentHeader">
					<NavbarImp hdlCP={this.hdlChangePage}/>
				</div>
				<div className="contentCenter">
					{content}
				</div>
				<FooterImp hdlCP={this.hdlChangePage}/>
      		</div>
    	)
  	};
}

export default Impory;
