import { Component, Fragment } from 'react';
import '../Impory.css';
import Impory from '../Impory';
import * as Constants from '../constant/constant';
import * as ImporyFunction from '../logic/ImporyFunction';
//import { Nav, Badge } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome, faEnvelope, faFilm, faUserCircle, faBell, faShoppingCart, faCopyright} from '@fortawesome/free-solid-svg-icons'
class PictureImpItem extends Component {
	
	state = {
		picture: {},
	}
	
	constructor(props){
		super(props);
		this.state.picture = this.props.pic;
	}

	setFullScreen = (pic) => {
		this.props.parent.setState({inFullScreen : true, inFullScreenImage : pic, inFullScreenIndex : this.props.index});
		console.log("New current index : " + this.props.index);
	}

	render(){
		let pic = this.props.pic;
		console.log('showit')
		console.log(pic);
		var content = '';
		var imageName = '';
		if(pic.name !== undefined){
			imageName = pic.name;
			imageName = imageName.replaceAll('&','\\');
			imageName = imageName.normalize();
			imageName = ImporyFunction.convertUnicode(imageName);
		}

		var sectionStyle = {
			backgroundImage: "url("+Constants.WS_PIC_MANAGER + "?tkn=" + Impory.obj.state.impToken + "&id1=" + pic.id + '&thn&id2=' + Impory.obj.state.userConnected.id+")",
		  };

		content = (
			<div className='PictureContainerItem' style={sectionStyle} onClick={() => {this.setFullScreen(pic)}}>
				<h5 className="">{imageName}</h5>
			</div>	
		);

    	return content;
  	};
	
}
export default PictureImpItem;