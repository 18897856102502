import { Component, Fragment } from 'react';
import '../Impory.css';
import {Circles} from "react-loader-spinner";
import Impory from '../Impory';
import { GoogleLogin } from '@react-oauth/google';
import { googleLogout } from '@react-oauth/google';
import * as Constant from '../constant/constant';
import * as ApiConnexion from '../api/apiConnexion'
//import { Nav, Badge } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faHome, faEnvelope, faFilm, faUserCircle, faBell, faShoppingCart, faCopyright} from '@fortawesome/free-solid-svg-icons'

const responseGoogle = (response) => {
	console.log('Fail Google Co');
}

class AcceuilImp extends Component {
	
	static obj;
	
	state = {
		isLoading: true,
		//error:'',
		
	}
	
	constructor(props){
		super(props);
		AcceuilImp.obj = this;
	}

	handleApiConnected = (responseGoogle) =>{
		ApiConnexion.apiConnection(responseGoogle,this);
	}
    errorMessage = (error) => {
        console.log(error);
    };

	render(){
		var content = '';
		var centerContent = '';
		if(this.state.isLoading){
			if(Impory.obj.state.isConnected){
				centerContent = (
					<div className="col-8">
						<img
								alt=""
								src="/assets/logo.png"
								width="200"
								height="200"
								className="d-inline-block align-top"
							/>
						<h3>Bienvenue sur Impory !</h3>
						<span>Connectez-vous grâce à votre compte Google ci-dessous.</span>
						<br/>
						<br/>
						<googleLogout
							buttonText="Déconnexion"
							onLogoutSuccess={() => {this.props.hdlDeco()}}
							>
						</googleLogout>
					</div>
				);
			}else{
				centerContent = (
					<div className="col-8" align="center">
						<img
								alt=""
								src="/assets/logo.png"
								width="200"
								height="200"
								className="d-inline-block align-top"
							/>
						<h3>Bienvenue sur Impory</h3>
						<span>Connectez-vous grâce à votre compte Google ci-dessous. </span>
						<br/>
						<br/>
						<GoogleLogin
							buttonText={Constant.T_B_GOOGLE_CONNEXION}
							onSuccess={this.handleApiConnected}
							onFailure={this.errorMessage}
							isSignedIn={false}
							auto_select
							size="large"
							cookiePolicy={'single_host_origin'}
						/>
					</div>
				);
			}
			
		}else{
			centerContent = (
				<div className="col-8" align="center">
					<Circles
						color="#FF6D14"
						height={100}
						width={100}
						wrapperClass="loading"
					/>
				</div>
			);
		}

		content = (
				<div className="rowImp">
					<div className="col">
						
					</div>
					{centerContent}
					<div className="col">
						
					</div>
				</div>
		);
    	return content;
  	};
	
	componentWillUnmount(){
		AcceuilImp.obj = undefined;
	}
}
export default AcceuilImp;