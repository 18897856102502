import * as Constants from '../constant/constant';
import { jwtDecode } from "jwt-decode";

export async function apiConnection(data, state){
	console.log(data);
	const decoded = jwtDecode(data.credential);
	console.log(decoded);
	var prenom = decoded.given_name;
	var nom = decoded.family_name;
	var mail = decoded.email;
	var pseudo = decoded.name;
	var uid = decoded.sub;
	var pic = decoded.picture;
	var token = decoded.jti;
	//localStorage.setItem("name", JSON.stringify(name));

	const headers = new Headers();
	headers.append('Content-type', 'application/json');
	var requestOut = {
		request:{
			id_version:Constants.numeroVersion,
			type_request:4,
			STRUC:'imp_user',
			param1:pseudo,
			param2:pic,
			param3:uid,
			param4:mail,
			param5:prenom,
			param6:nom,
			param9:data.credential,
		}
	}
	const options = {
		method : 'POST',
		headers,
		body: JSON.stringify(requestOut)
	};
	requestOut.request.name = data.name;
	console.log("Requête sortante : ", JSON.stringify(requestOut));
	const request = new Request(Constants.WS_REQ_MANAGER, options);
	await fetch(request)
		.then(response => {
			if (response.ok) {
				console.log("Reponse OK ! " + JSON.stringify(response));
				return response.json();
			}else {
				console.log("Reponse KO !");
				state.setState({error:'Anomalie inconnu',isLoading: false});
				return undefined;
			}
		  })
		.then(data => {
			console.log("Then data : " + JSON.stringify(data))
			state.setState({user:data,isLoading: true}, state.props.hdlConnexionSuccess(data.imp_user[0]))
		})
		.catch(err => state.setState({error:err, isLoading: false }));
	if(state.state.isLoading && (state.state.error === '' || state.state.error === undefined)){
		console.log('Data receive: ', JSON.stringify(state.state.user));
		//state.props.hdlConnexionSuccess(state.state.user.imp_user[0]);
	}else{
		console.log('Request in error : ' + state.state.error);
	}
}

export async function apiConnectionImpory(data, state){
	console.log("DATA : " + JSON.stringify(data.profileObj));
	var prenom = data.profileObj.givenName;
	var nom = data.profileObj.familyName;
	var mail = data.profileObj.email;
	var pseudo = data.profileObj.name;
	var uid = data.profileObj.googleId;
	var pic = data.profileObj.imageUrl;

	state.setState({isLoading : true});
	const headers = new Headers();
	headers.append('Content-type', 'application/json');
	var requestOut = {
		request:{
			id_version:Constants.numeroVersion,
			type_request:2,
			STRUC:'imp_user',
			param1:pseudo,
			param2:pic,
			param3:uid,
			param4:mail,
			param5:prenom,
			param6:nom,
		}
	}
	const options = {
		method : 'POST',
		headers,
		body: JSON.stringify(requestOut)
	};
	requestOut.request.name = data.name;
	console.log("Requête sortante : ", JSON.stringify(requestOut));
	const request = new Request(Constants.WS_REQ_MANAGER, options);
	await fetch(request)
		.then(response => {
			if (response.ok) {
				return response.json();
			}else {
				state.setState({error:'Anomalie inconnu',isLoading: false});
			}
		  })
		.then(data => state.setState({user:data,isLoading: false}))
		.catch(err => state.setState({error:err, isLoading: false }));;
	if(!state.state.isLoading && state.state.error === ''){
		console.log('Data receive1: ', state.state);
		console.log('Data receive2: ', JSON.stringify(state.state.user));
		state.props.hdlConnexionSuccess(state.state.user.imp_user[0]);
	}else{
		console.log('Request in error : ' + state.state.error);
	}
}