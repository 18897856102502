import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Impory from './Impory';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<GoogleOAuthProvider clientId="1042056844825-t2df3dbf74k1iv1np1efq8p1p33q1sg8.apps.googleusercontent.com">
  		<React.StrictMode>
    		<Impory />
  		</React.StrictMode>
	</GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
