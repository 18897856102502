import { Component } from 'react';
import '../Impory.css';
import {Circles} from "react-loader-spinner";
import * as Constants from '../constant/constant';
import Impory from '../Impory';
import * as apiStandard from '../api/apiStandard';
import * as CstRequest from '../constant/constantRequest';
import PictureImpItem from './PictureImpItem';
import PictureImpFull from './PictureImpFull';
import ReactPlayer from 'react-player';
import * as ImporyFunction from '../logic/ImporyFunction';
//import ImageUploader from 'react-images-upload';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import { Card  } from 'react-bootstrap';

class PictureImp extends Component {
	
	static obj;
	
	state = {
		isLoading: false,
		inFullScreen : false,
		inFullScreenImage : {},
		error: '',
		album:{},
		pictures:[], 		// Retour de API
		users:[], 		// Retour de API
		picturesDisplay:[], // Seulement les photos
		videosDisplay:[],   // Seulement les vidéos
		contentArray:[],    // Tout le contenu
	}
	
	constructor(props){
		super(props);
		PictureImp.obj = this;
		this.state.album = this.props.album;
		/*var dataGetPics = [this.props.album.id];
		apiStandard.sendRequest(CstRequest.CST_PICTURE, CstRequest.REQUEST_GALLERIE_GET_PICS, dataGetPics, this.onPictureLoad, this.onPictureLoadError);
		*/
		this.refreshData();
		this.props.hdlOnDropPic.bind(this);
	}

	refreshData = () => {
		var dataGetPics = [this.state.album.id];
		console.log("Send request : " + CstRequest.REQUEST_GALLERIE_GET_PICS);
		this.setState({isLoading: false});
		apiStandard.sendRequest(CstRequest.CST_PICTURE, CstRequest.REQUEST_GALLERIE_GET_PICS, dataGetPics, this.onPictureLoad, this.onPictureLoadError);
		apiStandard.sendRequest(CstRequest.CST_USER, CstRequest.REQUEST_GALERIE_GET_USER, dataGetPics, this.onUserLoad, this.onPictureLoadError);
	}
	onPictureLoadError = (strucName, idRequest, data, reponse) => {
		console.log("Request " + idRequest + " terminated: ko " + reponse);
	}
	onUserLoad = (strucName, idRequest, data, reponse) => {
		console.log("Request " + idRequest + " terminated.");
		const dataIn = reponse;
		this.setState({users : dataIn});
	}
	onPictureLoad = (strucName, idRequest, data, reponse) => {
		console.log("Request " + idRequest + " terminated.");
		const dataIn = reponse;
		this.setState({pictures : dataIn, isLoading: true})

		var images = dataIn.imp_picture.filter(pic => !pic.extension.toUpperCase().includes('MP4', 0)).map((picture, index) =>{
			var tmpObj = {};
			if(picture.extension.toUpperCase().includes('PNG', 0) || picture.extension.toUpperCase().includes('JPEG', 0) || picture.extension.toUpperCase().includes('JPG', 0)){
				tmpObj.url = Constants.WS_PIC_MANAGER + "?tkn=" + Impory.obj.state.impToken + "&id1=" + picture.id + '&id2=' + Impory.obj.state.userConnected.id;
				tmpObj.urlThn = Constants.WS_PIC_MANAGER + "?tkn=" + Impory.obj.state.impToken + "&id1=" + picture.id + '&id2=' + Impory.obj.state.userConnected.id + '&thn';
				if(picture.name!==undefined){
					tmpObj.title = picture.name;
				}else{
					tmpObj.title = "";
				}
				tmpObj.id = picture.id;
			}
			this.state.contentArray.push({original : tmpObj.url, thumbnail:tmpObj.urlThn});
			return tmpObj;
		});
		var videos = dataIn.imp_picture.filter(pic => pic.extension.toUpperCase().includes('MP4', 0)).map((videos, index) =>{
			var tmpObj = {};
			if(videos.extension.toUpperCase().includes('MP4', 0)){
				tmpObj.url = Constants.WS_VIDEO + videos.url;
				tmpObj.urlThn = Constants.WS_PIC_MANAGER + "?tkn=" + Impory.obj.state.impToken + "&id1=" + videos.id + '&id2=' + Impory.obj.state.userConnected.id + '&thn';
				tmpObj.title = videos.name;
				tmpObj.id = videos.id;
			}
			return tmpObj;
		});
		console.log(images);
		this.setState({picturesDisplay:images,videosDisplay:videos});
	}

	render(){
		var content = '';
		var centerContent = '';
		var albumName = this.state.album.name;

		if(albumName!==undefined){
			albumName=albumName.replaceAll('&','\\');
			albumName=albumName.normalize();
			albumName = ImporyFunction.convertUnicode(albumName);
		}else{
			albumName = 'Sans nom';
		}

		if(this.state.isLoading){
			if(this.state.inFullScreen){
				centerContent = (
					<PictureImpFull pic={this.state.inFullScreenImage} parent={this} index={this.state.inFullScreenIndex}/>
				);
			}else{
				centerContent = (
					<div className="col-12 rowImp contentMarger">
						<div className="col-xl-2 col-lg-2 col-md-3 col-12">
							<Card className="uploaderContainer">
								<Card.Body>
								<Card.Title>Ajouter des images</Card.Title>
								
								{
									Impory.obj.state.picturesUploadsUrls.map((file, index) =>{
										var fileName = "";
										if(file!==undefined){
											fileName = file.toString().substring(
													file.toString().indexOf("=") + 1, 
													file.toString().lastIndexOf(";"));
											
											return(
												<span>
													<FontAwesomeIcon icon={faTimes} 
														className="uploaderCross"
														onClick={() => {this.props.hdlOnRemovePicture(index)}}/> {fileName}
													<br/>
												</span>
											)
										}
									})
								}
								<button onClick={() => {this.props.hdlUpload()}} type="submit" class="btn btn-info">Ajouter à la galerie</button>
								</Card.Body>
							</Card>
							<Card className="uploaderContainer">
								<Card.Body>
									<Card.Title>Utilisateur dans la galerie</Card.Title>
									{
										this.state.users.imp_user.map((user, index) =>{
											if(user!==undefined){
												return(
													<span>
														<FontAwesomeIcon icon={faTimes} 
															className="uploaderCross"/> {user.pseudo}
														<br/>
													</span>
												)
											}
										})
									}
								</Card.Body>
							</Card>
						</div>
						<div className="col-xl-10 col-lg-10 col-md-9 col-12 contentMarger galerieContainer">
						<h3>{albumName}</h3>
						<div className="text-center text-lg-left rowImp">
							{
							this.state.videosDisplay.map((picture, index) =>{
								return(
									<div key={index} className="col-xl-3 col-lg-4 col-md-6 col-6 pictureContainer">
										<ReactPlayer url={picture.url} 
											width='100%'
											height='30vh'
											light={Constants.WS_PIC_MANAGER + "?tkn=" + Impory.obj.state.impToken + "&id1=" + picture.id + '&thn&id2=' + Impory.obj.state.userConnected.id}
											controls
											/>
									</div>
								)
							})
							}
							{
							this.state.picturesDisplay.map((picture, index) =>{
								const indx = index;
								return(
									<div key={index} className="col-xl-3 col-lg-4 col-md-6 col-6 pictureContainer">
										<PictureImpItem pic={picture} parent={this} index={indx}/>
									</div>
								)
							})
							}
							</div>
						</div>
					</div>
				);
			}
		}else{
			centerContent = (
				<div className="col-8" align="center">
					<Circles
						color="#FF6D14"
						height={100}
						width={100}
						wrapperClass="loading"
					/>
				</div>
			);
		}

		content = (
			<div className="rowImp">
				{centerContent}
			</div>
		);
    	return content;
  	};
	componentWillUnmount(){
		PictureImp.obj = undefined;
	}
}
export default PictureImp;