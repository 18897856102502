import { Component, Fragment } from 'react';
import '../Impory.css';
import * as Constants from '../constant/constant';
import {Navbar, Nav, Badge} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faEnvelope, faFilm, faUserCircle, faBell, faShoppingCart, faCopyright, faDownload} from '@fortawesome/free-solid-svg-icons'
import Impory from '../Impory';

class NavbarImp extends Component {
	
	static obj;
	
	state = {
		//isLoading: true,
		//error:'',
	}
	
	constructor(props){
		super(props);
		const spr = this.props.spr;
		NavbarImp.obj = this;
	}

	hdlChangePage = (idPage) =>{
		this.props.hdlCP(idPage);
	}

	render(){
		var content = '';
		
		if(Impory.obj.state.isConnected){
			content = (
				<div className="headerMenu rowImp">
					<Navbar collapseOnSelect expand="lg" variant="dark">
						<Navbar.Brand href="#home">
							<img
								alt=""
								src="/logo192.png"
								width="30"
								height="30"
								className="d-inline-block align-top"
							/>{' '}
							{Constants.T_APP_NAME}
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="mr-auto menuContainers">
								<Nav.Link className='headerNavItem' onClick={() => {this.hdlChangePage(Constants.PAGE_ACCEUIL)}}>
									<FontAwesomeIcon icon={faHome} /> {Constants.T_M_ACCEUIL} <Badge hidden pill variant="danger">1</Badge>
								</Nav.Link>
								<Nav.Link className='headerNavItem' onClick={() => {this.hdlChangePage(Constants.PAGE_ALBUM)}}>
									<FontAwesomeIcon icon={faFilm}/> {Constants.T_M_GALERIES} <Badge hidden pill variant="danger">2</Badge>
								</Nav.Link>
								<Nav.Link className='headerNavItem' onClick={()=> window.open("https://www.impory.com/aab/Impory.apk", "_blank")}>
									<FontAwesomeIcon icon={faDownload}/> {"Télécharger application"} <Badge hidden pill variant="danger">2</Badge>
								</Nav.Link>
								<Nav.Link className='headerNavItem' onClick={() => {this.hdlChangePage(Constants.PAGE_MESSAGE)}}>
									<FontAwesomeIcon icon={faEnvelope}/> {Constants.T_M_MESSAGES} <Badge hidden pill variant="danger">2</Badge>
								</Nav.Link>
								<Nav.Link className='headerNavItem' onClick={() => {this.hdlChangePage(Constants.PAGE_NOTIFICATION)}}>
									<FontAwesomeIcon icon={faBell}/> {Constants.T_M_NOTIFICATIONS} <Badge hidden pill variant="danger">2</Badge>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</div>
			);
		}else{
			content = (
				<div className="headerMenu rowImp">
					<Navbar collapseOnSelect expand="lg" variant="dark">
						<Navbar.Brand href="#home">
							<img
								alt=""
								src="/logo192.png"
								width="30"
								height="30"
								className="d-inline-block align-top"
							/>{' '}
							{Constants.T_APP_NAME}
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="responsive-navbar-nav" />
						<Navbar.Collapse id="responsive-navbar-nav">
							<Nav className="mr-auto menuContainers">
								<Nav.Link className='headerNavItem' onClick={() => {this.hdlChangePage(Constants.PAGE_ACCEUIL)}}>
									<FontAwesomeIcon icon={faHome} /> {Constants.T_M_CONNEXION} <Badge hidden pill variant="danger">1</Badge>
								</Nav.Link>
								<Nav.Link className='headerNavItem' onClick={()=> window.open("https://www.impory.com/aab/Impory.apk", "_blank")}>
									<FontAwesomeIcon icon={faDownload}/> {"Télécharger application"} <Badge hidden pill variant="danger">2</Badge>
								</Nav.Link>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</div>
			);
		}
    	return content;
  	};

	componentWillUnmount(){
		NavbarImp.obj = undefined;
	}

}
export default NavbarImp;