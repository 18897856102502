import { Component, Fragment } from 'react';
import '../Impory.css';
import * as Constants from '../constant/constant';
import Impory from '../Impory';
import * as ImporyFunction from '../logic/ImporyFunction';
//import { Nav, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCamera } from '@fortawesome/free-solid-svg-icons'

class AlbumImpItem extends Component {
	
	state = {
		//isLoading: true,
		//error:'',
	}
	
	constructor(props){
		super(props);
	}

	render(){
		var content = '';
		var album = this.props.album;

		var sectionStyle = {
			backgroundImage: "url("+Constants.WS_PIC_MANAGER + "?tkn=" + Impory.obj.state.impToken + "&id1=" + album.idP + '&thn&id2=' + Impory.obj.state.userConnected.id+")",
		};

		var albumName = album.name;
		if(albumName!==undefined){
			albumName=albumName.replaceAll('&','\\');
			albumName=albumName.normalize();
			albumName = ImporyFunction.convertUnicode(albumName);
		}

		content = (
			<div className="col-12 albumContainerItem">
				<div className='col-12 albumContainerItemImage contentMarger' 
						style={sectionStyle} onClick={() => {this.props.hdlCP(Constants.PAGE_PICTURES, album)}}>
				</div>
				<div className='col-12 albumContainerItemText contentMarger'>
					<h5>{albumName}</h5>
					<FontAwesomeIcon icon={faUser}/> {album.or} - <FontAwesomeIcon icon={faCamera}/> {album.nbPics}
				</div>
			</div>
		);
    	return content;
  	};
}
export default AlbumImpItem;